import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "osipov_about_liepaja" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p><Link to={ `/en/players/osipov` }>Artem Osipov</Link> gave a comment about his lawsuit against FK Liepaja:</p>
          <p>This happened in summer 2017. The management did everything it only could to squeeze me out of my employment. They stopped paying my salary and rent. They banned me from the team. I had to leave. Despite that, they claimed that I breached the employment contract. This was a brazen lie.</p>
          <p>Of course, they did not care about any contractual obligations they had to perform. They disregarded law. In a few months' time, they indulged in unwarranted smears about me in the Latvian press. I felt down.</p>
          <p>I thank you those professionals from Catenaccio firm, who propped me up during this very tough time. For the last two years, they worked dedicatedly to obtain the decision from FIFA against the unlawful practices used by the management of FK Liepaja.</p>
          <p>Catenaccio demonstrates that the interests of footballers are of primary importance for it. The firm does not play along with clubs' managers and owners. Exactly such attitude brings the organisation of Latvian football to the advanced standards of European leagues. Other my colleagues-footballers should know that there are professionals who provide protection against such injustice as I experienced in Liepaja.</p>
        </div>
      </div>
    )
  }
}